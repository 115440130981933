import request from '@/utils/request1';

// 贮存管理查询
export function keepList(data) {
    return request({ url: '/refrigerate/QRefrigerateApi', method: 'post', data })
}

// 贮存管理新增
export function keepAdd(data) {
    return request({ url: '/refrigerate/ARefrigerateApi', method: 'post', data })
}

// 贮存管理编辑
export function keepEdit(data) {
    return request({ url: '/refrigerate/URefrigerateApi', method: 'post', data })
}

// 贮存管理删除
export function keepDel(data) {
    return request({ url: '/refrigerate/DRefrigerateApi', method: 'post', data })
}

// 定期监测记录查询
export function monitorsList(data) {
    return request({ url: '/monitors/QMonitorsApi', method: 'post', data })
}

// 定期监测记录新增
export function monitorsAdd(data) {
    return request({ url: '/monitors/AMonitorsApi', method: 'post', data })
}

// 定期监测记录编辑
export function monitorsEdit(data) {
    return request({ url: '/monitors/UMonitorsApi', method: 'post', data })
}

// 定期监测记录删除
export function monitorsDel(data) {
    return request({ url: '/monitors/DMonitorsApi', method: 'post', data })
}

// 运输过程记录查询
export function transportsList(data) {
    return request({ url: '/transports/QTransportsApi', method: 'post', data })
}

// 运输过程记录新增
export function transportsAdd(data) {
    return request({ url: '/transports/ATransportsApi', method: 'post', data })
}

// 运输过程记录编辑
export function transportsEdit(data) {
    return request({ url: '/transports/UTransportsApi', method: 'post', data })
}

// 运输过程记录删除
export function transportsDel(data) {
    return request({ url: '/transports/DTransportsApi', method: 'post', data })
}

// 召回产品处理查询
export function callHandleList(data) {
    return request({ url: '/call_handle/QCallHandleApi', method: 'post', data })
}

// 召回产品处理新增
export function callHandleAdd(data) {
    return request({ url: '/call_handle/ACallHandleApi', method: 'post', data })
}

// 召回产品处理编辑
export function callHandleEdit(data) {
    return request({ url: '/call_handle/UCallHandleApi', method: 'post', data })
}

// 召回产品处理删除
export function callHandleDel(data) {
    return request({ url: '/call_handle/DCallHandleApi', method: 'post', data })
}
